<!--
 * @Description: 客户常用地址维护
 * @Author: LiangYiNing
 * @Date: 2022-03-15 09:28:25
 * @LastEditTime: 2022-07-15 15:54:08
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i
            class="e6-icon-add_line"
            title="新增常用地址"
            @click="handleAdd()"
          ></i>
          <i
            class="e6-icon-clear_line"
            title="删除常用地址"
            @click="handleDelete()"
          ></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="contactAddrName">
              <el-input
                v-model="searchForm.contactAddrName"
                placeholder="客户地址"
                title="客户地址"
                maxlength="100"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="createTime"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          ref="tableList"
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <AddCustomerCommomAddress
      ref="AddCustomerCommomAddress"
      v-if="AddCustomerCommomAddressVisible"
      @updateList="handleSearch"
    ></AddCustomerCommomAddress>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { getCorpAddrListPage, corpAddrDelete } from "@/api";
import AddCustomerCommomAddress from "@/components/AddCustomerCommomAddress";
export default {
  name: "CustomerCommomAddress",
  mixins: [base, listPage, listPageReszie],
  components: { AddCustomerCommomAddress },
  data() {
    return {
      searchForm: {
        corpId: "", //客户ID
        contactAddrName: "", //客户地址
        createTime: [],
        createdTimeStart: "", //开始时间
        createdTimeEnd: "", //结束时间
        pageIndex: 1,
        pageSize: 20
      },
      AddCustomerCommomAddressVisible: false,
      queryListAPI: getCorpAddrListPage,
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      corpList: [],
      total: 0,
      columnData: [
        {
          fieldName: "corpName",
          fieldLabel: "客户名称",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactAddrName",
          fieldLabel: "联系地址",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          fieldLabel: "备注",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdName",
          fieldLabel: "创建人",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          fieldLabel: "创建时间",
          width: 160,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据

      /*****添加/修改信息*******/
      addUserVisible: false,
      addCompanyForm: {
        companyFullName: "", //主体全称
        companySimName: "", //主体简称
        companyLevel: "", //主体等级
        remark: "" //备注
      },
      editCustomerDialogLoading: false,
      addCompanyRules: {
        companyFullName: [
          {
            required: true,
            message: "请填写主体全称",
            trigger: ["blur", "change"]
          }
        ],
        companySimName: [
          {
            required: true,
            message: "请填写主体简称",
            trigger: ["blur", "change"]
          }
        ],
        companyLevel: [
          {
            required: true,
            message: "请选择主体等级",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },

  computed: {},
  created() {
    this.queryList();
    this.loadCorpData("");
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      }
    }
  },
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    // 表格多选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return {
          addrId: item.addrId,
          dataSourceType: item.dataSourceType
        };
      });
    },
    //批量删除
    handleDelete(delDataList) {
      console.log(delDataList);
      delDataList =
        delDataList && delDataList.length ? delDataList : this.selColumnId;
      if (!delDataList.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除所选常用地址?", "删除常用地址", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.corpAddrDelete(delDataList);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //删除客户常用地址请求
    async corpAddrDelete(delDataList) {
      try {
        this.loading = true;
        let res = await corpAddrDelete(delDataList);
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleAdd(row) {
      this.AddCustomerCommomAddressVisible = true;
      this.$nextTick(() => {
        this.$refs.AddCustomerCommomAddress.init(row);
      });
    },
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        },
        {
          label: "删除",
          id: 2
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      switch (val.id) {
        case 1:
          this.handleAdd(row);
          break;
        case 2:
          this.handleDelete([
            {
              addrId: row.addrId,
              dataSourceType: row.dataSourceType
            }
          ]);
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
