<!--
 * @Description: 新增或修改客户常用地址弹窗
 * @Author: LiangYiNing
 * @Date: 2022-03-15 14:06:27
 * @LastEditTime: 2022-07-01 09:21:34
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 修改卡信息 start -->
  <el-dialog
    v-dialogDrag
    :title="`${form.addrId ? '修改' : '新增'}客户常用地址`"
    :visible="visible"
    @open="openDialog"
    append-to-body
    width="500px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    v-loading="editDialogLoading"
    custom-class="edit-customer-dialog"
  >
    <el-form
      ref="form"
      label-width="100px"
      :model="form"
      :rules="editSimFormRules"
    >
      <el-form-item label="客户名称" prop="corpId">
        <e6-vr-select
          v-if="!corpId"
          v-model="form.corpId"
          :data="corpList"
          placeholder="客户名称"
          title="客户名称"
          clearable
          virtual
          remote
          :is-title="true"
          @filterChange="handleLoadCorpFilter"
          :props="{
            id: 'corpId',
            label: 'corpName'
          }"
        ></e6-vr-select>
        <el-input v-model="corpName" disabled v-else></el-input>
      </el-form-item>
      <el-form-item label="所在地区" prop="defaultCity">
        <e6-vr-select
          style="width:300px"
          v-model="form.defaultCity"
          :data="addressList"
          placeholder="联系地址"
          title="联系地址"
          virtual
          clearable
          :is-title="true"
          remote
          @filterChange="handleLoadAddressFilter"
          @change="handleCitySelect"
          :props="{
            id: 'fullName',
            label: 'fullName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="详细地址" prop="addrName">
        <el-input
          v-model="form.addrName"
          placeholder="详细地址"
          maxlength="255"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          maxlength="100"
          placeholder="备注"
          title="备注"
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel dialog-btn" @click="closeDialog">取消</el-button>
      <el-button
        type="primary"
        class="dialog-btn"
        @click="handleSubmit"
        width="100"
        >确定</el-button
      >
    </div>
  </el-dialog>
  <!-- 修改卡信息 end -->
</template>

<script>
import base from "@/mixins/base";
import { corpAddrCreate, corpAddrUpdate, getAddressData } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "",
  mixins: [base],
  components: {},
  data() {
    return {
      visible: false,
      editDialogLoading: false,
      corpList: [],
      addressList: [],
      form: {
        addrId: "",
        corpId: "", //客户ID
        defaultCity: "",
        provinceId: "", // 省code
        townId: "", // 市code
        cityId: "", // 区县code
        cityName: "",
        streetId: "",
        streetName: "",
        addrName: "", //详细地址
        remark: "",
        dataSourceType: ""
      },
      editSimFormRules: {
        corpId: [
          {
            required: true,
            message: "请选择客户",
            trigger: ["blur", "change"]
          }
        ],
        defaultCity: [
          {
            required: true,
            message: "请选择省市区街道",
            trigger: ["blur", "change"]
          }
        ],
        addrName: [
          {
            required: true,
            message: "请填写详细地址",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: {
    corpId: {
      type: [Number, String]
    },
    corpName: {
      type: String
    }
  },
  computed: {},
  watch: {},
  methods: {
    init(row) {
      this.visible = true;
      this.$nextTick(() => {
        if (row && row.addrId) {
          this.displayForm(row);
        } else if (this.corpId) {
          this.form.corpId = this.corpId;
        }
      });
    },
    displayForm(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      if (row.addrName) {
        this.$set(
          this.form,
          "defaultCity",
          row.contactAddrName.replace(row.addrName, "")
        );
      }
      this.addressList = [
        {
          fullName: this.form.defaultCity,
          provinceCode: row.provinceId,
          cityCode: row.townId,
          countyCode: row.cityId,
          countyName: row.cityName,
          streetId: row.streetId,
          streetName: row.streetName
        }
      ];
      this.corpList = [
        {
          corpId: row.corpId,
          corpName: row.corpName
        }
      ];
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.form.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //点击确定添加
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.corpAddrUpdate();
        }
      });
    },
    async corpAddrUpdate() {
      try {
        this.editDialogLoading = true;
        let handleApi = corpAddrCreate;
        let msg = "新增成功";
        if (this.form.addrId) {
          handleApi = corpAddrUpdate;
          msg = "修改成功";
        }
        let res = await handleApi(this.form);
        if (res.code === "OK") {
          this.$message.success(msg);
          this.closeDialog();
          this.$emit("updateList");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //重置地址
    resetAddress() {
      this.form.defaultCity = "";
      this.form.provinceId = "";
      this.form.townId = "";
      this.form.cityId = "";
      this.form.cityName = "";
      this.form.streetId = "";
      this.form.streetName = "";
    },
    // 处理城市选择
    handleCitySelect(select, node) {
      let val = _.cloneDeep(node);
      this.form.provinceId = val.provinceCode;
      this.form.townId = val.cityCode;
      this.form.cityId = val.countyCode;
      this.form.cityName = val.countyName;
      this.form.streetId = val.streetId;
      this.form.streetName = val.streetName;
      this.form.defaultCity = val.fullName;
    },
    handleLoadAddressFilter: _.debounce(async function(val) {
      if (val) {
        this.resetAddress();
        this.loadAddressData(val);
      }
    }, 300),
    //加载地址列表
    async loadAddressData(val = "") {
      try {
        let res = await getAddressData({
          addressName: val
        });
        //获取地址下拉框
        this.addressList = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //打开
    openDialog() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    //关闭
    closeDialog() {
      this.visible = false;
      this.form = {
        addrId: "",
        corpId: "", //客户ID
        defaultCity: "",
        provinceId: "", // 省code
        townId: "", // 市code
        cityId: "", // 区县code
        cityName: "",
        streetId: "",
        streetName: "",
        addrName: "", //详细地址
        remark: "",
        dataSourceType: ""
      };
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-dialog__body {
    padding: 20px;
  }
  .el-form {
    .el-input {
      width: 300px;
    }
  }
  .dialog-btn {
    width: 100px;
  }
}
</style>
